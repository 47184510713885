import * as Types from '../../types';

import { gql } from '@apollo/client';
export type SubscriptionProductGridFieldsFragment = {
  id: string;
  stock: number;
  image?: { url: string; alt?: string | null } | null;
  value: { id: string; name: string; option: { id: string; name: string } };
};

export const SubscriptionProductGridFieldsFragmentDoc = gql`
  fragment subscriptionProductGridFields on ProductGridType {
    id
    image {
      url
      alt
    }
    stock
    value {
      id
      option {
        id
        name
      }
      name
    }
  }
`;
