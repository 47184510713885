import styled from '@emotion/styled';
import { FC } from 'react';
import { FeatureCarousel } from '../feature-carousel';
import { IndexFeature } from '../index-feature';
import { aboutUsFeatures } from './about-us-features';

const GridWrapper = styled.div`
  display: none;
  align-items: stretch;
  grid-template-columns: repeat(3, 1fr);
  gap: ${props => props.theme.spacing(2)};
  padding-bottom: ${props => props.theme.spacing(1)};
  ${props => props.theme.breakpoints.up('md')} {
    display: grid;
  }

  & > div {
    grid-column: span 1 / auto;
  }
`;

const StyledFeatureCarousel = styled(FeatureCarousel)`
  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const AboutUsCarousel: FC = () => (
  <>
    <GridWrapper>
      {aboutUsFeatures.map(it => (
        <IndexFeature key={it.id ?? it.title} feature={it} />
      ))}
    </GridWrapper>
    <StyledFeatureCarousel features={aboutUsFeatures} />
  </>
);
