import styled from '@emotion/styled';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Typography } from '@mui/material';
import { Breakpoint } from '@mui/system';
import { FC, forwardRef, ReactNode } from 'react';
import { GenericStoreCard } from './generic-store-card';

export interface StoreMoreCardProps {
  /** Class name to be added to the generic card, useful for styled components */
  className?: string;
  /** A H5 primary colored text */
  title?: string | ReactNode;
  /** A body2 text secondary colored text */
  subtitle?: string | ReactNode;
  /** Handler function when the card is clicked */
  onClick?: () => unknown;
  /** When the screen is below this breakpoint it stops being a grid and starts being
   *  a horizontally scroll container  */
  horizontalBreakpoint?: Breakpoint;
  /** The minimum width of the cards when in horizontal mode */
  horizontalItemMinWidth?: string;
  /** The vertical padding number (from theme.spacing) of the card, default number: 6 */
  paddingY?: number;
}
const StyledControlPointIcon = styled(ControlPointIcon)`
  font-size: ${props => props.theme.spacing(8)};
`;

const CardContent = styled.div<{ paddingY?: number }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${props => props.theme.spacing(props.paddingY ?? 6, 4)};
`;

export const StoreMoreCard: FC<StoreMoreCardProps> = forwardRef<
  HTMLButtonElement,
  StoreMoreCardProps
>(
  (
    {
      className,
      title = 'Ver mais',
      subtitle = 'Veja todos os produtos disponíveis',
      onClick,
      paddingY,
      ...props
    },
    ref,
  ) => {
    return (
      <GenericStoreCard className={className} onClick={onClick} {...props} ref={ref}>
        <CardContent paddingY={paddingY}>
          <StyledControlPointIcon color="primary" />
          <Typography variant="h5" color="primary">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {subtitle}
          </Typography>
        </CardContent>
      </GenericStoreCard>
    );
  },
);
