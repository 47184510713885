import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TestimonialFieldsFragment } from 'core/lib/graphql/_gen/testimonials';
import { FC, useMemo } from 'react';
import { SwiperSlide } from 'swiper/react';
import { CustomSwiper, SwiperProps } from '../../../common/components/custom-swiper';
import { TestimonialBox } from './testimonial-box';

const SpacedSwiper = styled(CustomSwiper)<{ hasNavigation?: boolean }>`
  ${props =>
    props.hasNavigation
      ? css`
          ${props.theme.breakpoints.down('sm')} {
            .swiper-slide > div {
              margin: 0 40px;
            }
          }
          .swiper-button-prev::after,
          .swiper-button-next::after {
            color: #666;
            font-size: 1rem;
            font-weight: bold;
          }
        `
      : ''}
`;

interface TestimonialsCarouselProps extends SwiperProps {
  testimonials: TestimonialFieldsFragment[];
}

export const TestimonialsCarousel: FC<TestimonialsCarouselProps> = ({ testimonials, ...props }) => {
  const testimonialsSlides = useMemo(
    () =>
      testimonials.map(it => (
        <SwiperSlide key={it.id}>
          <TestimonialBox
            image={it.image}
            name={it.title}
            description={it.text}
            lifetime={it.subtitle!}
            lazy
          />
        </SwiperSlide>
      )),
    [testimonials],
  );
  return <SpacedSwiper {...props}>{testimonialsSlides}</SpacedSwiper>;
};
