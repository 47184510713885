export const verooFaq = [
  {
    id: '1',
    question: 'Como funciona a assinatura de cafés?',
    answer: `Ao entrar para o Clube Veroo, todo mês você receberá uma box com o café inédito do mês, recém torrado e produzido por pequenos produtores de todo o país. Nossos especialistas escolhem cuidadosamente cada café para garantir a melhor qualidade. Além disso, você também receberá mensalmente um mimo surpresa para deixar a sua experiência ainda mais gostosa.

      Ao fazer parte do Clube Veroo, você se torna parte de uma comunidade apaixonada por café. Além de incentivar o consumo consciente, você embarcará em uma jornada no mundo dos cafés especiais junto com a gente, explorando todo esse universo sem sair do conforto da sua casa.

      Ao assinar o Clube Veroo, você também está apoiando o trabalho de pequenos produtores brasileiros. Com isso, você também terá a oportunidade de conhecer a história de quem está por trás da sua xícara todos os meses e estará contribuindo para um mercado de café mais justo, digno e consciente para todos
`,
  },
  {
    id: '2',
    question: 'A assinatura da Veroo tem fidelidade?',
    answer: `Nosso objetivo é oferecer flexibilidade aos nossos assinantes. Todos os planos permitem que você pause a sua assinatura por até 2 meses caso opte por não receber em determinado período.

      Nos planos mensais, você é livre para cancelar quando quiser. Já nos planos anuais, há um tempo mínimo de permanência de 12 meses. Para mais informações, você pode consultar no nosso termos de uso`,
  },
  {
    id: '3',
    question: 'Como é feito o pagamento dos planos anuais e dos mensais?',
    answer: `Oferecemos duas opções de pagamento para os planos anuais. Você pode escolher entre boleto/pix à vista ou pagar em até 12 vezes sem juros no cartão de crédito. No caso do pagamento com cartão de crédito, o valor total é descontado do limite disponível e dividido em parcelas de acordo com sua escolha.

      Nos planos mensais, o pagamento é feito exclusivamente por cartão de crédito. O valor será descontado mensalmente de forma recorrente no seu cartão.`,
  },
  {
    id: '4',
    question: 'Quais são os benefícios do clube?',
    answer:
      'Curadoria com especialistas que viajam o Brasil inteiro provando os melhores cafés; Acesso aos melhores cafés produzidos por pequenos produtores do nosso país; Acesso a lotes de cafés especiais exclusivos e inéditos que não encontraria no mercado; Explora um café especial inédito todos os meses; Não precisa se preocupar em comprar cafés ou escolher os melhores. Nós fazemos isso por você (e com muito carinho, sempre); 10% de desconto em toda loj; Mimos surpresas todos os meses na sua box para complementar a sua experiência; Oportunidade de conhecer a história por trás de todos os cafés que você experimentar e incentivar o consumo consciente; Fazer parte da comunidade mais cafezeira do Brasil e aprender cada dia mais sobre o mundo dos cafés especiais',
  },
  {
    id: '5',
    question: 'As cápsulas da Veroo são de alumínio? e são compatíveis com quais máquinas?',
    answer:
      'Nossas cápsulas são feitas de alumínio e são compatíveis com as máquinas Nespresso. Utilizamos a melhor tecnologia disponível para proporcionar a melhor experiência ao preparar o seu café.',
  },
  {
    id: '6',
    question: 'Quando é feita a torra dos cafés do clube?',
    answer:
      'Realizamos as torras semanalmente internamente na Torrefação Veroo, garantindo que o café sempre chegue fresquinho para os nossos assinantes.',
  },
  {
    id: '7',
    question: 'Como faço para cancelar ou pausar minha assinatura?',
    answer: `Cancelar ou pausar sua assinatura é fácil! Basta acessar o seu painel, clicar em "editar assinatura" e alterar o "status da assinatura" de acordo com a sua preferência.

      Para cancelamento de assinaturas anuais, entre em contato com o nosso suporte`,
  },
  {
    id: '8',
    question:
      'Quando será cobrado mensalmente a minha assinatura e quando será enviado o meu pedido todo mês?',
    answer: `Você será cobrado todos os meses no dia em que realizou a assinatura. Por exemplo, se você assinou no dia 15 do mês, sua fatura será gerada sempre nesse dia, ao menos que você altere a data de cobrança no seu painel.

      Após a confirmação do pagamento, o seu pedido será gerado e em seguida montado em até 24 horas úteis e enviado para a transportadora.`,
  },
  {
    id: '9',
    question: 'Quando vou receber a minha box?',
    answer:
      'O tempo de entrega varia de acordo com a localização do destinatário e depende da transportadora. Em São Paulo capital, o tempo médio de entrega após o envio é de 48 horas.',
  },
  {
    id: '10',
    question: 'Como consigo o desconto no frete na minha assinatura?',
    answer:
      'Você receberá automaticamente um desconto no frete na sua assinatura ao escolher um plano com o valor mínimo de R$50,00. Esse valor mínimo é válido para planos a partir de 500g ou 30 cápsulas.',
  },
  {
    id: '11',
    question: 'Como faço para acompanhar o status do meu pedido?',
    answer:
      'Você pode acompanhar o status da sua assinatura tanto pelo seu painel, quanto pelo site da transportadora (indicado no seu pedido). Além disso, nós sempre enviaremos atualizações por e-mail sobre o status do seu pedido, para que você fique sempre por dentro do que está acontecendo.',
  },
  {
    id: '12',
    question:
      'Como a Veroo consegue pagar pelo menos 25% a mais nas negociações com os pequenos produtores?',
    answer: `Fazer todo esse trabalho não é tarefa fácil, mas quando se tem um propósito por trás, torna-se um fator motivador.

      Nós compramos o café cru direto do pequeno produtor, eliminamos os intermediários de toda a cadeia, estudamos o lote, fazemos uma torra adequada e levamos o café direto para casa do consumidor. Isso permite uma negociação com uma margem maior com o produtor, que é de pelo menos 25% acima do mercado de commodity, e a prática do comércio justo.

      No entanto, antes do “boom” dos preços do café na bolsa de valores, já negociamos com o produtor 100% a mais no preço das sacas, em relação ao mercado.`,
  },
];
