/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box } from '@mui/material';
import { BANNER_CHANGE_TIMEOUT } from '@web/common/constants/timers';
import { VEROO_ORIGIN } from '@web/common/constants/url';
import { BannerFieldsFragment } from 'core/lib/graphql/_gen/banners';
import { Maybe } from 'graphql/jsutils/Maybe';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { SwiperSlide } from 'swiper/react';
import { Banner } from '.';
import { DefaultSingleBanner } from './default-single-banner';

export interface CarouselBannersWithFallbackProps {
  id?: string;
  banners: (BannerFieldsFragment | undefined | null)[];
  autoPlayDelay?: number;
}

interface BannerLinkWrapperProps {
  ctaAction?: Maybe<string>;
}

const BannerLinkWrapper: FC<BannerLinkWrapperProps> = ({ children, ctaAction }) => {
  const { query } = useRouter();

  if (ctaAction == null) {
    return <div>{children}</div>;
  }

  const url = new URL(ctaAction);
  Object.entries(query).forEach(([k, v]) => {
    if (typeof v === 'string') {
      url.searchParams.append(k, v);
    }
  });
  const isSameOrigin = url.origin === VEROO_ORIGIN;
  return (
    <NextLink href={url} passHref>
      <a
        target={!isSameOrigin ? '_blank' : undefined}
        rel={!isSameOrigin ? 'nofollow noreferrer' : undefined}
      >
        {children}
      </a>
    </NextLink>
  );
};

const CustomSwiper = dynamic<any>(() => import('@web/common/components/custom-swiper'), {
  ssr: true,
});

export const CarouselBannersWithFallback: FC<CarouselBannersWithFallbackProps> = ({
  id,
  banners,
  autoPlayDelay = BANNER_CHANGE_TIMEOUT,
}) => (
  <Box id={id} pt={2}>
    {banners.length > 1 ? (
      <CustomSwiper delay={autoPlayDelay} hasNavigation={false}>
        {banners.map(it => (
          <SwiperSlide key={it?.id}>
            <BannerLinkWrapper ctaAction={it?.ctaAction}>
              <Banner
                bannerDesktop={it?.bannerDesktop}
                bannerMobile={it?.bannerMobile}
                hasBorderRadius
              />
            </BannerLinkWrapper>
          </SwiperSlide>
        ))}
      </CustomSwiper>
    ) : (
      <BannerLinkWrapper ctaAction={banners[0]?.ctaAction}>
        <DefaultSingleBanner banner={banners[0]} hasBorderRadius />
      </BannerLinkWrapper>
    )}
  </Box>
);
