import { OptimizedImage } from '@web/common/components/optimized-image';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import { mediaPartners } from '@web/common/constants/media-partners';
import styled from '@emotion/styled';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import { FC } from 'react';
import { Link } from '@mui/material';
import LazyLoad from 'react-lazyload';

const MediaLinkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const PartnerImageLogo = styled(OptimizedImage)`
  img {
    max-width: ${props => props.theme.spacing(20)};
    height: ${props => props.theme.spacing(4)};
  }
`;

const VideoContainer = styled.div`
  max-width: 100%;
  width: 570px;
  margin: 0 auto;
  margin-bottom: ${props => props.theme.spacing(5)};
`;

const MediaItemLink = styled.div`
  margin: ${props => props.theme.spacing(1, 6)};
  ${props => props.theme.breakpoints.down('sm')} {
    margin: ${props => props.theme.spacing(1, 1)};
  }
`;

export const MediaSection: FC = () => (
  <>
    <VideoContainer>
      <LazyLoad height={192} offset={100}>
        <LiteYouTubeEmbed
          id="RblVtF9og-o"
          title="Veroo cafés"
          activatedClass="lyt-activated"
          iframeClass=""
          playerClass="lty-playbtn"
          wrapperClass="yt-lite"
          announce=""
        />
      </LazyLoad>
    </VideoContainer>
    <MediaLinkContainer>
      {mediaPartners.map(partner => (
        <MediaItemLink key={partner.url}>
          <Link
            underline="none"
            href={partner.url}
            title={partner.title}
            target="_blank"
            rel="nofollow noreferrer"
          >
            <PartnerImageLogo
              lazy
              lazyHeight={32}
              src={partner.img}
              ext="png"
              title={partner.title}
              alt={partner.alt}
            />
          </Link>
        </MediaItemLink>
      ))}
    </MediaLinkContainer>
  </>
);
