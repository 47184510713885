import { BannerFieldsFragment } from 'core/lib/graphql/_gen/banners';
import { FC } from 'react';
import { Banner } from '.';
import { LayoutBannerProps } from './interface';

const BANNER_DEFAULT_PROPS: LayoutBannerProps = {
  maxWidth: 'lg',
  title1: 'O clube perfeito',
  title2: 'para quem ama café',
  subtitle1: 'Receba os melhores cafés do Brasil',
  subtitle2: 'e uma box perfeita para sua melhor experiência.',
  ctaAction: '/subscription',
  ctaLabel: 'Assinar',
};

interface DefaultSingleBannerProps {
  banner?: BannerFieldsFragment | undefined | null;
  hasBorderRadius?: boolean;
}

export const DefaultSingleBanner: FC<DefaultSingleBannerProps> = ({ banner, hasBorderRadius }) => {
  const props = { ...BANNER_DEFAULT_PROPS, ...banner };
  return <Banner {...props} hasBorderRadius={hasBorderRadius} />;
};
