import { Feature } from '../feature-interface';

export const howItWorks: Feature[] = [
  {
    title: 'Escolha um plano e assine',
    subtitle:
      'Escolha entre cafés em cápsulas, grãos e moído. Na Veroo, você está no controle. Não temos letras miúdas.',
    img: '/images/landing/how-it-works/step-1.svg',
  },
  {
    title: 'Conte com a curadoria de nosso barista',
    subtitle:
      'A comodidade e segurança para receber, aonde quiser, cafés escolhidos por quem entende do assunto.',
    img: '/images/landing/how-it-works/step-2.svg',
  },
  {
    title: 'Conheça os verdadeiros artistas dos cafés',
    subtitle:
      'Nossas experiências sensoriais são criadas para fazer os consumidores viajarem para a roça sem sair de casa',
    img: '/images/landing/how-it-works/step-3.svg',
  },
  {
    title: 'Brindes exclusivos na assinatura',
    subtitle:
      'Todo o mês você recebe um mimo surpresa para incrementar ainda mais a sua experiência.',
    img: '/images/landing/how-it-works/step-4.svg',
  },
  {
    title: 'Assinantes Veroo tem 10 % off em todo site',
    subtitle:
      'Assinantes têm 10 % off em todo site e frete reduzido nas compras acima de R$ 50,00.',
    img: '/images/landing/how-it-works/step-5.svg',
  },
];
