import styled from '@emotion/styled';
import { PaperProps, Typography } from '@mui/material';
import { OptimizedImage, OptimizedImageProps } from '@web/common/components/optimized-image';
import { Feature } from '@web/common/feature-interface';
import { SmartImage } from '@web/common/smart-image';
import { FC } from 'react';
import { SlidePaper } from './slide-paper';

interface IndexFeatureProps {
  feature: Feature;
  paperProps?: Partial<PaperProps>;
}

const FeatureSmartImage = styled(SmartImage)`
  img {
    max-width: 100%;
    height: auto;
    border-radius: ${props => props.theme.shape.borderRadius}px;
  }
`;

const FeatureOptimizedImage = styled(OptimizedImage)`
  img {
    max-width: 100%;
    height: auto;
    border-radius: ${props => props.theme.shape.borderRadius}px;
  }
`;

const defaultImgProps: Partial<OptimizedImageProps> = { width: '100%', height: 'auto' };

export const IndexFeature: FC<IndexFeatureProps> = ({ feature, paperProps }) => (
  <SlidePaper {...paperProps}>
    {feature.img != null && (
      <FeatureOptimizedImage {...defaultImgProps} {...feature.imgProps} src={feature.img} />
    )}
    {feature.smartImage != null && (
      <FeatureSmartImage {...feature.smartImageProps} image={feature.smartImage} />
    )}
    <Typography variant="subtitle1" component="h3">
      {feature.title}
    </Typography>
    {feature.subtitle != null && (
      <Typography variant="body2" color="textSecondary">
        {feature.subtitle}
      </Typography>
    )}
  </SlidePaper>
);
