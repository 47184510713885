import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { meliuzStorage } from '../meliuz/meliuz-storage';

const isValidKey = <T extends Record<string, unknown>>(obj: T, key: string) =>
  key in obj && typeof obj[key] === 'string' && (obj[key] as string).length > 0;

/**
 * This hook save the meliuz cashback code when it exists on the url query params
 * e.g: https://veroo.com.br/?utm_source=meliuz&xtra=T312009102
 * */
export const useSaveMeliuzCashbackCode = () => {
  const { query } = useRouter();
  useEffect(() => {
    if (query.utm_source === 'meliuz' && isValidKey(query, 'xtra')) {
      meliuzStorage.addOrUpdateKey('cashbackCode', query.xtra);
    }
  }, [query]);
};
