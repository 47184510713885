import { verooFaq } from '@web/common/constants/faq';
import Head from 'next/head';
import { FC } from 'react';

const microdataFaq = verooFaq.map(faq => ({
  '@type': 'Question',
  'name': faq.question,
  'acceptedAnswer': {
    '@type': 'Answer',
    'text': faq.answer,
  },
}));

const makeFAQSchema = () => ({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  'mainEntity': microdataFaq,
});

export const FAQData: FC = () => {
  return (
    <Head>
      <script
        key="Veroo-FAQ"
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: JSON.stringify(makeFAQSchema()) }}
      />
    </Head>
  );
};
