import { Box, Container, Typography, TypographyProps } from '@mui/material';
import { Breakpoint } from '@mui/system';

import { FC } from 'react';

interface GenericSectionContainerProps {
  /** Container id for anchor scrolling */
  id?: string;
  /** A H4 sized text */
  title: string;
  /** An object to overwrite the original Typography props for the title */
  titleProps?: TypographyProps;
  /** A body2 sized text */
  subtitle?: string;
  /** An object to overwrite the original Typography props for the subtitle */
  subtitleProps?: TypographyProps;
  /** The text alignment of the title and subtitle */
  titleAlign?: TypographyProps['align'];
  /** The container max-width following the Material-UI breakpoints */
  maxWidth?: Breakpoint;
  /** A flag to remove the horizontal padding */
  disableGutters?: boolean;
}

export const GenericSectionContainer: FC<GenericSectionContainerProps> = ({
  id,
  title,
  titleProps,
  subtitle,
  subtitleProps,
  titleAlign = 'left',
  maxWidth,
  disableGutters,
  children,
}) => {
  return (
    <Container
      maxWidth={maxWidth}
      id={id}
      disableGutters={disableGutters}
      fixed
      data-e2e={`generict-section-container-${title.split(' ').slice(0, 1).join('')}`}
    >
      {title && (
        <Box mb={3}>
          <Typography
            variant="h4"
            {...({ align: titleAlign } as any)}
            {...titleProps}
            component="h2"
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="body2" align={titleAlign} color="textSecondary" {...subtitleProps}>
              {subtitle}
            </Typography>
          )}
        </Box>
      )}
      <div>{children}</div>
    </Container>
  );
};
