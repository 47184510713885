export const mediaPartners = [
  {
    title: 'Café e cultura',
    url: 'https://revistacafeicultura.com.br/index.php?tipo=ler&mat=70460&clube-de-assinatura-de-caf---investe-no-selo-eureciclo.html',
    img: '/images/media-logos/cafeicultura.png',
    alt: 'Café e cultura logo',
  },
  {
    title: 'Draft',
    url: 'https://www.projetodraft.com/conheca-o-clube-de-assinatura-de-cafe-da-veroo-e-receba-todo-mes-seu-companheiro-de-todas-as-horas-moido-em-graos-ou-em-capsulas/',
    img: '/images/media-logos/draft.png',
    alt: 'Draft logo',
  },
  {
    title: 'Terra viva',
    url: 'https://www.youtube.com/watch?v=RblVtF9og-o',
    img: '/images/media-logos/terraviva.png',
    alt: 'Terra viva logo',
  },
  {
    title: 'Notícias Agrícolas',
    url: 'https://www.noticiasagricolas.com.br/videos/cafe/287257-startup-adota-modelo-de-fidelizacao-para-promover-cafes-especiais.html#.YOZBLi272-p',
    img: '/images/media-logos/noticias-agricolas.png',
    alt: 'Notícias agricolas logo',
  },
  {
    title: 'Valor agregado',
    url: 'https://valoragregado.com/2021/04/13/startup-liga-pequenos-produtores-de-cafe-a-fas-da-bebida/',
    img: '/images/media-logos/valor-agregado.png',
    alt: 'Valor agregado logo',
  },
];
