import { OptimizedImage } from '@blb-ventures/react-components/dist';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { Breakpoint } from '@mui/system';
import { FC, forwardRef } from 'react';

export interface GenericStoreCardProps {
  className?: string;
  disabled?: boolean;
  onClick?: () => unknown;
  horizontalBreakpoint?: Breakpoint;
  horizontalItemMinWidth?: string;
  ref?: any;
}

export const ItemImage = styled(OptimizedImage)`
  img {
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: ${props => props.theme.shape.borderRadius}px;
  }
`;

export const StyledButton = styled(Button)<{
  $disabled?: boolean;
  $horizontalBreakpoint?: Breakpoint;
  $horizontalItemMinWidth?: string;
}>`
  height: 100%;
  max-width: 100%;
  opacity: ${props => (props.$disabled ? 0.5 : 1)};
  display: block;
  & > div {
    text-align: center;
  }
  flex-grow: 0;
  flex-basis: calc(20% - ${props => props.theme.spacing(2)});
  max-width: calc(20% - ${props => props.theme.spacing(2)});
  margin: ${props => props.theme.spacing(1)};
  height: auto;

  ${props => props.theme.breakpoints.down('md')} {
    flex-basis: calc(33% - ${props => props.theme.spacing(2)});
    max-width: calc(33% - ${props => props.theme.spacing(2)});
  }
  ${props => props.theme.breakpoints.down('sm')} {
    flex-basis: calc(50% - ${props => props.theme.spacing(1)});
    max-width: calc(50% - ${props => props.theme.spacing(1)});
    margin: ${props => props.theme.spacing(0.5)};
  }
  ${props => props.theme.breakpoints.down(props.$horizontalBreakpoint ?? 1)} {
    flex-shrink: 0;
    flex-basis: initial;
    max-width: initial;
    width: ${props => props.$horizontalItemMinWidth};
  }

  padding: ${props => props.theme.spacing(1)};
  ${props => props.theme.breakpoints.up('md')} {
    padding: ${props => props.theme.spacing(2)};
  }
`;

export const GenericStoreCard: FC<GenericStoreCardProps> = forwardRef<
  HTMLButtonElement,
  GenericStoreCardProps
>(
  (
    {
      className,
      disabled,
      onClick,
      children,
      horizontalBreakpoint,
      horizontalItemMinWidth,
      ...props
    },
    ref,
  ) => {
    return (
      <StyledButton
        $disabled={disabled}
        className={className}
        onClick={onClick}
        $horizontalBreakpoint={horizontalBreakpoint}
        $horizontalItemMinWidth={horizontalItemMinWidth}
        variant="outlined"
        color="primary"
        {...props}
        ref={ref}
      >
        {children}
      </StyledButton>
    );
  },
);
