import styled from '@emotion/styled';
import { ButtonBase, Typography, TypographyProps } from '@mui/material';
import { Breakpoint } from '@mui/system';
import { SmartImage, SmartImageType } from '@web/common/smart-image';
import { theme } from '@web/common/theme';
import { FC, forwardRef, ReactNode } from 'react';
import { CardStatus } from '../interfaces';
import { GenericStoreCard } from './generic-store-card';

type StoreCardOptions = {
  titleStyle: TypographyProps;
  subtitleStyle?: TypographyProps;
  bodyDetailStyle?: TypographyProps;
  bodyDetailHasLineThrough?: boolean;
  bodyTextStyle?: TypographyProps;
};

export interface StoreProductCardProps {
  /** Class name to be added to the generic card, useful for styled components */
  className?: string;
  /** Status of the product used to show a little badge on the top right corner of the card */
  status?: CardStatus;
  /** Text written on the badge on the top right corner of the card colored by the status */
  topInfoText?: string;
  image?: SmartImageType | null;
  /** Usually the product name, will only show one line of text the rest will be truncated */
  title: string | ReactNode;
  /** Usually the product origin, will only show one line of text the rest will be truncated */
  subtitle?: string | ReactNode;
  /** Usually the product original price, will only show one line of text the rest will
   *  be truncated */
  bodyDetail?: string | ReactNode;
  /** Usually the product price, will only show one line of text the rest will be truncated */
  bodyText?: string | ReactNode;
  /** Typography options for most of the card's text */
  options?: StoreCardOptions;
  /** Handler function when the card is clicked */
  onClick?: () => unknown;
  /** When the screen is below this breakpoint it stops being a grid and starts being
   *  a horizontally scroll container  */
  horizontalBreakpoint?: Breakpoint;
  /** The minimum width of the cards when in horizontal mode */
  horizontalItemMinWidth?: string;
  /** Whether the product images should be lazy loaded or not */
  lazy?: boolean;
}

const defaultOptions: StoreCardOptions = {
  titleStyle: { variant: 'subtitle1' },
  subtitleStyle: { variant: 'caption' },
  bodyDetailStyle: {
    color: 'textSecondary',
    variant: 'body2',
    style: {
      textDecoration: 'line-through',
    },
  },
  bodyTextStyle: { color: 'inherit', variant: 'h6' },
};

const cardStatusBgColors: Record<CardStatus, string> = {
  [CardStatus.Discount]: theme.palette.secondary.main,
  [CardStatus.New]: theme.palette.primary.main,
  [CardStatus.SoldOut]: theme.palette.grey['600'],
};
const cardStatusFgColors: Record<CardStatus, string> = {
  [CardStatus.Discount]: theme.palette.secondary.contrastText,
  [CardStatus.New]: theme.palette.primary.contrastText,
  [CardStatus.SoldOut]: theme.palette.getContrastText(theme.palette.grey['600']),
};

const TopInfoContainer = styled(Typography)<{ className: CardStatus }>`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${props => cardStatusBgColors[props.className]};
  color: ${props => cardStatusFgColors[props.className]};
  border-radius: ${props => props.theme.shape.borderRadius}px;
  padding: ${props => props.theme.spacing(0.2, 1)};
  font-weight: bold;
  ${props => props.theme.breakpoints.up('sm')} {
    padding: ${props => props.theme.spacing(0.4, 1.5)};
  }
`;

const ImageContainer = styled.div`
  position: relative;
`;

export const ItemImage = styled(SmartImage)`
  img {
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: ${props => props.theme.shape.borderRadius}px;
  }
`;

export const StyledButton = styled(ButtonBase)<{ disabled: boolean }>`
  height: 100%;
  max-width: 100%;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  display: block;
`;

const TextContainer = styled.div`
  text-align: center;
  color: ${props => props.theme.palette.text.primary};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  & > div:first-child {
    flex: 1;
    margin-bottom: ${props => props.theme.spacing(0.2)};
  }
`;

const TopText = styled.div`
  min-height: 52px;
`;

const BottomText = styled.div`
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const EllipsisText = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TitleEllipsisText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: normal;
  max-height: calc(${props => props.theme.typography.subtitle1.lineHeight}rem * 2);
  text-overflow: ellipsis;
`;

export const StoreProductCard: FC<StoreProductCardProps> = forwardRef<
  HTMLButtonElement,
  StoreProductCardProps
>(
  (
    {
      className,
      status,
      image,
      title,
      subtitle,
      bodyDetail,
      bodyText,
      topInfoText,
      options,
      onClick,
      lazy,
      ...props
    },
    ref,
  ) => {
    const opts = { ...defaultOptions, ...options };
    return (
      <GenericStoreCard
        disabled={status === CardStatus.SoldOut}
        className={className}
        onClick={onClick}
        {...props}
        ref={ref}
        data-e2e="store-product-card"
      >
        <ImageContainer>
          {image != null && <ItemImage image={image} lazy={lazy} lazyProps={{ height: 150 }} />}
          {status && (
            <TopInfoContainer className={status} variant="caption">
              {status === CardStatus.Discount ? topInfoText : status}
            </TopInfoContainer>
          )}
        </ImageContainer>
        <TextContainer>
          <TopText>
            <TitleEllipsisText {...opts.titleStyle} {...{ component: 'h3' }}>
              {title}
            </TitleEllipsisText>
            <EllipsisText {...opts.subtitleStyle}>{subtitle}</EllipsisText>
          </TopText>
          {(bodyDetail || bodyText) && (
            <BottomText>
              {bodyDetail && <EllipsisText {...opts.bodyDetailStyle}>{bodyDetail}</EllipsisText>}
              {bodyText && (
                <EllipsisText {...opts.bodyTextStyle} {...{ component: 'h4' }}>
                  {bodyText}
                </EllipsisText>
              )}
            </BottomText>
          )}
        </TextContainer>
      </GenericStoreCard>
    );
  },
);
