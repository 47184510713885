import * as Types from '../../types';

import { gql } from '@apollo/client';
import { ProductGridOptionsFragmentDoc } from '../../store/_gen/product';
import { SubscriptionProductGridFieldsFragmentDoc } from './subscription-product-grid-fragment';
export type SubscriptionPlanProductFragment = {
  id: string;
  minimumQuantity: number;
  product: {
    id: string;
    name: string;
    quantity: number;
    quantityStr?: string | null;
    originalPrice?: number | null;
    price: number;
    kind: Types.ProductKind;
    coffeeKind?: Types.ProductCoffeeKind | null;
    stock: number;
    grids: Array<{
      id: string;
      stock: number;
      value: { id: string; name: string; option: { id: string; name: string } };
      image?: { url: string; alt?: string | null } | null;
    }>;
    images: Array<{ file?: { url: string; alt?: string | null } | null }>;
  };
  gridOptionValue?: { id: string; name: string; option: { id: string; name: string } } | null;
  productGrid?: {
    id: string;
    stock: number;
    image?: { url: string; alt?: string | null } | null;
    value: { id: string; name: string; option: { id: string; name: string } };
  } | null;
};

export const SubscriptionPlanProductFragmentDoc = gql`
  fragment subscriptionPlanProduct on SubscriptionPlanProductType {
    id
    product {
      id
      name
      quantity
      quantityStr
      originalPrice
      price
      kind
      coffeeKind
      stock
      grids {
        ...productGridOptions
      }
      images {
        file {
          url
          alt
        }
      }
    }
    minimumQuantity
    gridOptionValue {
      id
      option {
        id
        name
      }
      name
    }
    productGrid {
      ...subscriptionProductGridFields
    }
  }
  ${ProductGridOptionsFragmentDoc}
  ${SubscriptionProductGridFieldsFragmentDoc}
`;
