import { SelectOption } from '@web/common/components/form/select-field';
import { ProductCoffeeKind, ProductKind } from 'core/lib/graphql/types.d';
import { ParsedUrlQuery } from 'querystring';

export type SelectableProductKinds = Exclude<ProductKind, ProductKind.Template>;

export const productKindDetails: Record<SelectableProductKinds, { label: string; icon: any }> = {
  [ProductKind.Accessory]: { label: 'Acessórios', icon: '/icons/accessory.svg' },
  [ProductKind.Coffee]: { label: 'Cafés', icon: '/icons/coffee.svg' },
  [ProductKind.Kit]: { label: 'Kits', icon: '/icons/kit.svg' },
  [ProductKind.Utensil]: { label: 'Utensílios', icon: '/icons/appliance.svg' },
};

export const coffeeKindLabelDict: Record<ProductCoffeeKind, string> = {
  [ProductCoffeeKind.Capsule]: 'Cápsulas',
  [ProductCoffeeKind.Beans]: 'Grãos',
  [ProductCoffeeKind.Ground]: 'Moído',
};

// Queries
export const productKindToQueryDict: Record<SelectableProductKinds, string> = {
  [ProductKind.Accessory]: 'acessorios',
  [ProductKind.Coffee]: 'cafes',
  [ProductKind.Kit]: 'kits',
  [ProductKind.Utensil]: 'utensilios',
};

export const queryToProductKindDict: Record<string, SelectableProductKinds> = {
  acessorios: ProductKind.Accessory,
  cafes: ProductKind.Coffee,
  kits: ProductKind.Kit,
  utensilios: ProductKind.Utensil,
};

export const coffeeKindToQueryDict: Record<ProductCoffeeKind, string> = {
  [ProductCoffeeKind.Capsule]: 'capsulas',
  [ProductCoffeeKind.Beans]: 'graos',
  [ProductCoffeeKind.Ground]: 'moido',
};

export const queryToCoffeeKindDict: Record<string, ProductCoffeeKind> = {
  capsulas: ProductCoffeeKind.Capsule,
  graos: ProductCoffeeKind.Beans,
  moido: ProductCoffeeKind.Ground,
};

export enum SortQueryParams {
  ProductKindParam = 'productKind',
}

export const orderByOptions = {
  'price': 'Preço crescente',
  '-price': 'Preço decrescente',
  '-createdAt': 'Mais recentes',
  'name': 'Ordem alfabética',
};
export type OrderByOptionsKeys = keyof typeof orderByOptions;
export const orderBySelectOptions: SelectOption[] = (
  Object.keys(orderByOptions) as OrderByOptionsKeys[]
).map(key => ({ label: orderByOptions[key], value: key }));

export const addMenuSortQuery = (query: ParsedUrlQuery, param: string): ParsedUrlQuery => {
  const newQuery = { ...query };
  newQuery[SortQueryParams.ProductKindParam] = param;
  return newQuery;
};

interface GetSortOptionsParams {
  kind?: SelectableProductKinds;
  kinds?: SelectableProductKinds[];
  coffeeKind?: ProductCoffeeKind;
  coffeeKinds?: ProductCoffeeKind[];
}

export const getSortOptionsParams = (query: ParsedUrlQuery): GetSortOptionsParams => {
  const queryCategoryKind = query[SortQueryParams.ProductKindParam];
  const categoryKind = typeof queryCategoryKind === 'string' ? queryCategoryKind : '';
  const [qKind, qSubCategory] = categoryKind.split('-');

  const kind =
    typeof qKind === 'string' && queryToProductKindDict.hasOwnProperty(qKind)
      ? queryToProductKindDict[qKind]
      : undefined;

  const coffeeKind =
    typeof qSubCategory === 'string' && queryToCoffeeKindDict.hasOwnProperty(qSubCategory)
      ? queryToCoffeeKindDict[qSubCategory]
      : undefined;

  return {
    kind,
    kinds: kind != null ? [kind] : [],
    coffeeKind,
    coffeeKinds: coffeeKind != null ? [coffeeKind] : undefined,
  };
};
