import styled from '@emotion/styled';
import { Container } from '@mui/material';

export const StyledContainerWithSections = styled(Container)`
  & > div {
    margin-top: ${props => props.theme.spacing(10)};
    &:first-child {
      margin-top: ${props => props.theme.spacing(0)};
    }
    &:nth-child(2) {
      margin-top: ${props => props.theme.spacing(5)};
    }
  }
  padding-bottom: ${props => props.theme.spacing(10)};
`;
