/* eslint-disable react/no-array-index-key */
import styled from '@emotion/styled';
import { howItWorks } from '@web/common/constants/how-it-works';
import { FC } from 'react';
import { FeatureCarousel } from './feature-carousel';
import { IndexFeature } from './index-feature';

const GridWrapper = styled.div`
  display: none;
  grid-template-columns: repeat(5, 1fr);
  gap: ${props => props.theme.spacing(2)};
  align-items: stretch;
  padding-bottom: ${props => props.theme.spacing(1)};
  ${props => props.theme.breakpoints.up('md')} {
    display: grid;
  }

  & > div {
    grid-column: span 1 / auto;
  }
`;

const StyledFeatureCarousel = styled(FeatureCarousel)`
  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const IndexHowItWorks: FC = () => (
  <>
    <GridWrapper>
      {howItWorks.map((it, idx) => (
        <IndexFeature key={idx} feature={it} paperProps={{ variant: 'elevation', elevation: 0 }} />
      ))}
    </GridWrapper>
    <StyledFeatureCarousel
      paperProps={{ variant: 'elevation', elevation: 0 }}
      features={howItWorks}
    />
  </>
);
