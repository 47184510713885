import { useEffect } from 'react';

interface TriggerOnPositionParams {
  callbackCondition?: boolean;
  callback: () => void;
}
interface TriggerWithPosition extends TriggerOnPositionParams {
  triggerPosition: number;
  elementId?: never;
}

interface TriggerWithId extends TriggerOnPositionParams {
  triggerPosition?: never;
  elementId: string;
}

type Trigger = TriggerWithPosition | TriggerWithId;

export const useTriggerOnPosition = ({
  triggerPosition,
  elementId,
  callback,
  callbackCondition = true,
}: Trigger): void => {
  const triggerListening = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    const getTriggerOnPosition = (
      elementId != null
        ? winScroll +
          Math.round(document.getElementById(elementId)?.getBoundingClientRect().top ?? 0)
        : triggerPosition
    ) as number;

    if (winScroll > getTriggerOnPosition && callbackCondition) {
      callback();
      window.removeEventListener('scroll', triggerListening);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', triggerListening, { passive: true });
    return () => {
      window.removeEventListener('scroll', triggerListening);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
