import styled from '@emotion/styled';
import { PaperProps } from '@mui/material';
import { CustomSwiper } from '@web/common/components/custom-swiper';
import { Feature } from '@web/common/feature-interface';
import { FC, useMemo } from 'react';
import { SwiperOptions } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import { IndexFeature } from './index-feature';

const Wrapper = styled.div<{ $hasNegativeMargin: boolean }>`
  ${props => props.theme.breakpoints.down('md')} {
    margin: ${props => (props.$hasNegativeMargin ? '0px -16px' : undefined)};
  }
`;

export interface FeatureCarouselProps {
  features?: Feature[];
  loop?: boolean;
  hasNavigation?: boolean;
  slidesPerView?: number;
  centeredSlides?: boolean;
  swiperProps?: Partial<SwiperOptions>;
  hasNegativeMargin?: boolean;
  className?: string;
  paperProps?: Partial<PaperProps>;
}

export const FeatureCarousel: FC<FeatureCarouselProps> = ({
  features,
  loop,
  swiperProps,
  hasNavigation,
  slidesPerView = 1.2,
  centeredSlides = true,
  hasNegativeMargin = true,
  children,
  className,
  paperProps,
}) => {
  const ElList = useMemo(
    () =>
      features?.map(it => (
        <SwiperSlide key={it.id ?? it.title}>
          <IndexFeature key={it.id ?? it.title} feature={it} paperProps={paperProps} />
        </SwiperSlide>
      )),
    [features, paperProps],
  );
  return (
    <Wrapper $hasNegativeMargin={hasNegativeMargin} className={className}>
      <CustomSwiper
        loop={loop}
        slidesPerView={slidesPerView}
        hasNavigation={hasNavigation}
        centeredSlides={centeredSlides}
        swiperProps={swiperProps}
      >
        {features != null ? ElList : children}
      </CustomSwiper>
    </Wrapper>
  );
};
