import { usePromoCodeLazyQuery } from 'core/lib/graphql/_gen/promocode';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { promocodeStorage } from '../promocode/promocode-storage';
import { encodeId } from '../relay';

export const useSavePromocode = () => {
  const { query } = useRouter();
  const code =
    typeof query.promocode === 'string' && query.promocode.length > 0 ? query.promocode : undefined;
  const { planId } = query;
  const plan = typeof planId === 'string' ? encodeId('SubscriptionPlanType', planId) : undefined;
  const [getPromocode, { data, called }] = usePromoCodeLazyQuery({ fetchPolicy: 'network-only' });

  useEffect(() => {
    if (code != null && !called) {
      getPromocode({ variables: { code, plan } });
    }
    if (data?.promocode?.id != null) {
      for (const discount of data.promocode.discounts) {
        promocodeStorage.addOrUpdateKey(
          discount.usage,
          data?.promocode.isMgm ? code : data?.promocode?.code,
        );
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [data, called, getPromocode, code]);
};
