import * as Types from '../../types';

import { gql } from '@apollo/client';
import { ProductFieldsFragmentDoc } from '../../store/_gen/product';
import { ProductGridFieldsFragmentDoc } from './product-grid-fields.fragment';
export type PromoFieldsFragment = {
  id: string;
  name: string;
  code: string;
  isMgm: boolean;
  description?: string | null;
  expiresAt: Date;
  quantity: number;
  gifts: Array<{
    id: string;
    quantity: number;
    subKind?: Types.SubscriptionKind | null;
    product: {
      quantityStr?: string | null;
      descriptionHtml: string;
      id: string;
      name: string;
      slug: string;
      price: number;
      originalPrice?: number | null;
      isFresh: boolean;
      isFeatured: boolean;
      isOnSale: boolean;
      onSaleDiscount?: number | null;
      clubPrice: number;
      stock: number;
      coffeeKind?: Types.ProductCoffeeKind | null;
      kind: Types.ProductKind;
      quantity: number;
      altitude?: number | null;
      characteristics?: string | null;
      score?: number | null;
      olfactory?: string | null;
      visual?: string | null;
      taste?: string | null;
      unit: Types.ProductUnit;
      metaTitle: string;
      metaDescription: string;
      scaReportUrl?: string | null;
      width?: number | null;
      height?: number | null;
      depth?: number | null;
      weight?: number | null;
      foodPairing?: string | null;
      isSoldOut?: boolean | null;
      grids: Array<{
        id: string;
        stock: number;
        value: { id: string; name: string; option: { id: string; name: string } };
        image?: { url: string; alt?: string | null } | null;
      }>;
      region?: {
        id: string;
        name: string;
        stateCode: string;
        slug: string;
        state: string;
        image?: { url: string; alt?: string | null } | null;
      } | null;
      feedbacks: Array<{
        id: string;
        name: string;
        comment: string;
        subtitle?: string | null;
        avatar?: { url: string; alt?: string | null } | null;
      }>;
      producer?: {
        id: string;
        name: string;
        farm: string;
        slug: string;
        descriptionHtml: string;
        video?: string | null;
        region: { name: string; state: string; stateCode: string };
        images: Array<{
          id: string;
          file?: { alt?: string | null; url: string } | null;
          original?: { alt?: string | null; url: string } | null;
          thumbnail?: { alt?: string | null; url: string } | null;
        }>;
        videoThumbnail?: { alt?: string | null; url: string } | null;
        avatar?: { alt?: string | null; url: string } | null;
      } | null;
      variety?: {
        id: string;
        name: string;
        slug: string;
        image?: { url: string; alt?: string | null } | null;
      } | null;
      images: Array<{
        id: string;
        file?: { url: string; alt?: string | null } | null;
        original?: { url: string; alt?: string | null } | null;
        thumbnail?: { url: string; alt?: string | null } | null;
      }>;
    };
    productGrid?: {
      id: string;
      stock: number;
      value: { id: string; name: string; option: { id: string; name: string } };
      image?: { alt?: string | null; url: string } | null;
    } | null;
  }>;
  discounts: Array<{
    id: string;
    kind: Types.PromocodeKind;
    subKind?: Types.SubscriptionKind | null;
    usage: Types.FinalityKind;
    discountPercent?: number | null;
    discountValue?: number | null;
    discountMinimumValue?: number | null;
    discountForCycles: number;
  }>;
  mgmGifts: Array<{
    id: string;
    subKind?: Types.SubscriptionKind | null;
    quantity: number;
    product: {
      quantityStr?: string | null;
      descriptionHtml: string;
      id: string;
      name: string;
      slug: string;
      price: number;
      originalPrice?: number | null;
      isFresh: boolean;
      isFeatured: boolean;
      isOnSale: boolean;
      onSaleDiscount?: number | null;
      clubPrice: number;
      stock: number;
      coffeeKind?: Types.ProductCoffeeKind | null;
      kind: Types.ProductKind;
      quantity: number;
      altitude?: number | null;
      characteristics?: string | null;
      score?: number | null;
      olfactory?: string | null;
      visual?: string | null;
      taste?: string | null;
      unit: Types.ProductUnit;
      metaTitle: string;
      metaDescription: string;
      scaReportUrl?: string | null;
      width?: number | null;
      height?: number | null;
      depth?: number | null;
      weight?: number | null;
      foodPairing?: string | null;
      isSoldOut?: boolean | null;
      grids: Array<{
        id: string;
        stock: number;
        value: { id: string; name: string; option: { id: string; name: string } };
        image?: { url: string; alt?: string | null } | null;
      }>;
      region?: {
        id: string;
        name: string;
        stateCode: string;
        slug: string;
        state: string;
        image?: { url: string; alt?: string | null } | null;
      } | null;
      feedbacks: Array<{
        id: string;
        name: string;
        comment: string;
        subtitle?: string | null;
        avatar?: { url: string; alt?: string | null } | null;
      }>;
      producer?: {
        id: string;
        name: string;
        farm: string;
        slug: string;
        descriptionHtml: string;
        video?: string | null;
        region: { name: string; state: string; stateCode: string };
        images: Array<{
          id: string;
          file?: { alt?: string | null; url: string } | null;
          original?: { alt?: string | null; url: string } | null;
          thumbnail?: { alt?: string | null; url: string } | null;
        }>;
        videoThumbnail?: { alt?: string | null; url: string } | null;
        avatar?: { alt?: string | null; url: string } | null;
      } | null;
      variety?: {
        id: string;
        name: string;
        slug: string;
        image?: { url: string; alt?: string | null } | null;
      } | null;
      images: Array<{
        id: string;
        file?: { url: string; alt?: string | null } | null;
        original?: { url: string; alt?: string | null } | null;
        thumbnail?: { url: string; alt?: string | null } | null;
      }>;
    };
    productGrid?: {
      id: string;
      stock: number;
      value: { id: string; name: string; option: { id: string; name: string } };
      image?: { alt?: string | null; url: string } | null;
    } | null;
  }>;
};

export type PromoGiftFieldsFragment = {
  id: string;
  quantity: number;
  subKind?: Types.SubscriptionKind | null;
  product: {
    quantityStr?: string | null;
    descriptionHtml: string;
    id: string;
    name: string;
    slug: string;
    price: number;
    originalPrice?: number | null;
    isFresh: boolean;
    isFeatured: boolean;
    isOnSale: boolean;
    onSaleDiscount?: number | null;
    clubPrice: number;
    stock: number;
    coffeeKind?: Types.ProductCoffeeKind | null;
    kind: Types.ProductKind;
    quantity: number;
    altitude?: number | null;
    characteristics?: string | null;
    score?: number | null;
    olfactory?: string | null;
    visual?: string | null;
    taste?: string | null;
    unit: Types.ProductUnit;
    metaTitle: string;
    metaDescription: string;
    scaReportUrl?: string | null;
    width?: number | null;
    height?: number | null;
    depth?: number | null;
    weight?: number | null;
    foodPairing?: string | null;
    isSoldOut?: boolean | null;
    grids: Array<{
      id: string;
      stock: number;
      value: { id: string; name: string; option: { id: string; name: string } };
      image?: { url: string; alt?: string | null } | null;
    }>;
    region?: {
      id: string;
      name: string;
      stateCode: string;
      slug: string;
      state: string;
      image?: { url: string; alt?: string | null } | null;
    } | null;
    feedbacks: Array<{
      id: string;
      name: string;
      comment: string;
      subtitle?: string | null;
      avatar?: { url: string; alt?: string | null } | null;
    }>;
    producer?: {
      id: string;
      name: string;
      farm: string;
      slug: string;
      descriptionHtml: string;
      video?: string | null;
      region: { name: string; state: string; stateCode: string };
      images: Array<{
        id: string;
        file?: { alt?: string | null; url: string } | null;
        original?: { alt?: string | null; url: string } | null;
        thumbnail?: { alt?: string | null; url: string } | null;
      }>;
      videoThumbnail?: { alt?: string | null; url: string } | null;
      avatar?: { alt?: string | null; url: string } | null;
    } | null;
    variety?: {
      id: string;
      name: string;
      slug: string;
      image?: { url: string; alt?: string | null } | null;
    } | null;
    images: Array<{
      id: string;
      file?: { url: string; alt?: string | null } | null;
      original?: { url: string; alt?: string | null } | null;
      thumbnail?: { url: string; alt?: string | null } | null;
    }>;
  };
  productGrid?: {
    id: string;
    stock: number;
    value: { id: string; name: string; option: { id: string; name: string } };
    image?: { alt?: string | null; url: string } | null;
  } | null;
};

export type MgmPromoGiftFieldsFragment = {
  id: string;
  subKind?: Types.SubscriptionKind | null;
  quantity: number;
  product: {
    quantityStr?: string | null;
    descriptionHtml: string;
    id: string;
    name: string;
    slug: string;
    price: number;
    originalPrice?: number | null;
    isFresh: boolean;
    isFeatured: boolean;
    isOnSale: boolean;
    onSaleDiscount?: number | null;
    clubPrice: number;
    stock: number;
    coffeeKind?: Types.ProductCoffeeKind | null;
    kind: Types.ProductKind;
    quantity: number;
    altitude?: number | null;
    characteristics?: string | null;
    score?: number | null;
    olfactory?: string | null;
    visual?: string | null;
    taste?: string | null;
    unit: Types.ProductUnit;
    metaTitle: string;
    metaDescription: string;
    scaReportUrl?: string | null;
    width?: number | null;
    height?: number | null;
    depth?: number | null;
    weight?: number | null;
    foodPairing?: string | null;
    isSoldOut?: boolean | null;
    grids: Array<{
      id: string;
      stock: number;
      value: { id: string; name: string; option: { id: string; name: string } };
      image?: { url: string; alt?: string | null } | null;
    }>;
    region?: {
      id: string;
      name: string;
      stateCode: string;
      slug: string;
      state: string;
      image?: { url: string; alt?: string | null } | null;
    } | null;
    feedbacks: Array<{
      id: string;
      name: string;
      comment: string;
      subtitle?: string | null;
      avatar?: { url: string; alt?: string | null } | null;
    }>;
    producer?: {
      id: string;
      name: string;
      farm: string;
      slug: string;
      descriptionHtml: string;
      video?: string | null;
      region: { name: string; state: string; stateCode: string };
      images: Array<{
        id: string;
        file?: { alt?: string | null; url: string } | null;
        original?: { alt?: string | null; url: string } | null;
        thumbnail?: { alt?: string | null; url: string } | null;
      }>;
      videoThumbnail?: { alt?: string | null; url: string } | null;
      avatar?: { alt?: string | null; url: string } | null;
    } | null;
    variety?: {
      id: string;
      name: string;
      slug: string;
      image?: { url: string; alt?: string | null } | null;
    } | null;
    images: Array<{
      id: string;
      file?: { url: string; alt?: string | null } | null;
      original?: { url: string; alt?: string | null } | null;
      thumbnail?: { url: string; alt?: string | null } | null;
    }>;
  };
  productGrid?: {
    id: string;
    stock: number;
    value: { id: string; name: string; option: { id: string; name: string } };
    image?: { alt?: string | null; url: string } | null;
  } | null;
};

export type PromoDiscountFieldsFragment = {
  id: string;
  kind: Types.PromocodeKind;
  subKind?: Types.SubscriptionKind | null;
  usage: Types.FinalityKind;
  discountPercent?: number | null;
  discountValue?: number | null;
  discountMinimumValue?: number | null;
  discountForCycles: number;
};

export const PromoGiftFieldsFragmentDoc = gql`
  fragment promoGiftFields on PromocodeGiftType {
    id
    product {
      ...productFields
    }
    quantity
    subKind
    productGrid {
      ...productGridFields
    }
  }
  ${ProductFieldsFragmentDoc}
  ${ProductGridFieldsFragmentDoc}
`;
export const PromoDiscountFieldsFragmentDoc = gql`
  fragment promoDiscountFields on PromocodeDiscountType {
    id
    kind
    subKind
    usage
    discountPercent
    discountValue
    discountMinimumValue
    discountForCycles
  }
`;
export const MgmPromoGiftFieldsFragmentDoc = gql`
  fragment mgmPromoGiftFields on PromocodeMgmGiftType {
    id
    product {
      ...productFields
    }
    subKind
    quantity
    productGrid {
      ...productGridFields
    }
  }
  ${ProductFieldsFragmentDoc}
  ${ProductGridFieldsFragmentDoc}
`;
export const PromoFieldsFragmentDoc = gql`
  fragment promoFields on PromocodeType {
    id
    name
    code
    isMgm
    description
    expiresAt
    gifts {
      ...promoGiftFields
    }
    discounts {
      ...promoDiscountFields
    }
    quantity
    mgmGifts {
      ...mgmPromoGiftFields
    }
  }
  ${PromoGiftFieldsFragmentDoc}
  ${PromoDiscountFieldsFragmentDoc}
  ${MgmPromoGiftFieldsFragmentDoc}
`;
