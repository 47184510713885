import * as Types from '../types';

import { gql } from '@apollo/client';
import { PromoFieldsFragmentDoc } from '../fragments/_gen/promocode.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PromoCodeQueryVariables = Types.Exact<{
  code: Types.Scalars['String'];
  plan?: Types.InputMaybe<Types.Scalars['ID']>;
}>;

export type PromoCodeQuery = {
  promocode?: {
    id: string;
    name: string;
    code: string;
    isMgm: boolean;
    description?: string | null;
    expiresAt: Date;
    quantity: number;
    gifts: Array<{
      id: string;
      quantity: number;
      subKind?: Types.SubscriptionKind | null;
      product: {
        quantityStr?: string | null;
        descriptionHtml: string;
        id: string;
        name: string;
        slug: string;
        price: number;
        originalPrice?: number | null;
        isFresh: boolean;
        isFeatured: boolean;
        isOnSale: boolean;
        onSaleDiscount?: number | null;
        clubPrice: number;
        stock: number;
        coffeeKind?: Types.ProductCoffeeKind | null;
        kind: Types.ProductKind;
        quantity: number;
        altitude?: number | null;
        characteristics?: string | null;
        score?: number | null;
        olfactory?: string | null;
        visual?: string | null;
        taste?: string | null;
        unit: Types.ProductUnit;
        metaTitle: string;
        metaDescription: string;
        scaReportUrl?: string | null;
        width?: number | null;
        height?: number | null;
        depth?: number | null;
        weight?: number | null;
        foodPairing?: string | null;
        isSoldOut?: boolean | null;
        grids: Array<{
          id: string;
          stock: number;
          value: { id: string; name: string; option: { id: string; name: string } };
          image?: { url: string; alt?: string | null } | null;
        }>;
        region?: {
          id: string;
          name: string;
          stateCode: string;
          slug: string;
          state: string;
          image?: { url: string; alt?: string | null } | null;
        } | null;
        feedbacks: Array<{
          id: string;
          name: string;
          comment: string;
          subtitle?: string | null;
          avatar?: { url: string; alt?: string | null } | null;
        }>;
        producer?: {
          id: string;
          name: string;
          farm: string;
          slug: string;
          descriptionHtml: string;
          video?: string | null;
          region: { name: string; state: string; stateCode: string };
          images: Array<{
            id: string;
            file?: { alt?: string | null; url: string } | null;
            original?: { alt?: string | null; url: string } | null;
            thumbnail?: { alt?: string | null; url: string } | null;
          }>;
          videoThumbnail?: { alt?: string | null; url: string } | null;
          avatar?: { alt?: string | null; url: string } | null;
        } | null;
        variety?: {
          id: string;
          name: string;
          slug: string;
          image?: { url: string; alt?: string | null } | null;
        } | null;
        images: Array<{
          id: string;
          file?: { url: string; alt?: string | null } | null;
          original?: { url: string; alt?: string | null } | null;
          thumbnail?: { url: string; alt?: string | null } | null;
        }>;
      };
      productGrid?: {
        id: string;
        stock: number;
        value: { id: string; name: string; option: { id: string; name: string } };
        image?: { alt?: string | null; url: string } | null;
      } | null;
    }>;
    discounts: Array<{
      id: string;
      kind: Types.PromocodeKind;
      subKind?: Types.SubscriptionKind | null;
      usage: Types.FinalityKind;
      discountPercent?: number | null;
      discountValue?: number | null;
      discountMinimumValue?: number | null;
      discountForCycles: number;
    }>;
    mgmGifts: Array<{
      id: string;
      subKind?: Types.SubscriptionKind | null;
      quantity: number;
      product: {
        quantityStr?: string | null;
        descriptionHtml: string;
        id: string;
        name: string;
        slug: string;
        price: number;
        originalPrice?: number | null;
        isFresh: boolean;
        isFeatured: boolean;
        isOnSale: boolean;
        onSaleDiscount?: number | null;
        clubPrice: number;
        stock: number;
        coffeeKind?: Types.ProductCoffeeKind | null;
        kind: Types.ProductKind;
        quantity: number;
        altitude?: number | null;
        characteristics?: string | null;
        score?: number | null;
        olfactory?: string | null;
        visual?: string | null;
        taste?: string | null;
        unit: Types.ProductUnit;
        metaTitle: string;
        metaDescription: string;
        scaReportUrl?: string | null;
        width?: number | null;
        height?: number | null;
        depth?: number | null;
        weight?: number | null;
        foodPairing?: string | null;
        isSoldOut?: boolean | null;
        grids: Array<{
          id: string;
          stock: number;
          value: { id: string; name: string; option: { id: string; name: string } };
          image?: { url: string; alt?: string | null } | null;
        }>;
        region?: {
          id: string;
          name: string;
          stateCode: string;
          slug: string;
          state: string;
          image?: { url: string; alt?: string | null } | null;
        } | null;
        feedbacks: Array<{
          id: string;
          name: string;
          comment: string;
          subtitle?: string | null;
          avatar?: { url: string; alt?: string | null } | null;
        }>;
        producer?: {
          id: string;
          name: string;
          farm: string;
          slug: string;
          descriptionHtml: string;
          video?: string | null;
          region: { name: string; state: string; stateCode: string };
          images: Array<{
            id: string;
            file?: { alt?: string | null; url: string } | null;
            original?: { alt?: string | null; url: string } | null;
            thumbnail?: { alt?: string | null; url: string } | null;
          }>;
          videoThumbnail?: { alt?: string | null; url: string } | null;
          avatar?: { alt?: string | null; url: string } | null;
        } | null;
        variety?: {
          id: string;
          name: string;
          slug: string;
          image?: { url: string; alt?: string | null } | null;
        } | null;
        images: Array<{
          id: string;
          file?: { url: string; alt?: string | null } | null;
          original?: { url: string; alt?: string | null } | null;
          thumbnail?: { url: string; alt?: string | null } | null;
        }>;
      };
      productGrid?: {
        id: string;
        stock: number;
        value: { id: string; name: string; option: { id: string; name: string } };
        image?: { alt?: string | null; url: string } | null;
      } | null;
    }>;
  } | null;
};

export const PromoCodeDocument = gql`
  query PromoCode($code: String!, $plan: ID) {
    promocode(code: $code, plan: $plan) {
      ...promoFields
    }
  }
  ${PromoFieldsFragmentDoc}
`;

/**
 * __usePromoCodeQuery__
 *
 * To run a query within a React component, call `usePromoCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *      plan: // value for 'plan'
 *   },
 * });
 */
export function usePromoCodeQuery(
  baseOptions: Apollo.QueryHookOptions<PromoCodeQuery, PromoCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PromoCodeQuery, PromoCodeQueryVariables>(PromoCodeDocument, options);
}
export function usePromoCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PromoCodeQuery, PromoCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PromoCodeQuery, PromoCodeQueryVariables>(PromoCodeDocument, options);
}
export type PromoCodeQueryHookResult = ReturnType<typeof usePromoCodeQuery>;
export type PromoCodeLazyQueryHookResult = ReturnType<typeof usePromoCodeLazyQuery>;
export type PromoCodeQueryResult = Apollo.QueryResult<PromoCodeQuery, PromoCodeQueryVariables>;
