import { Feature } from '@web/common/feature-interface';

export const aboutUsFeatures: Feature[] = [
  {
    title: 'Manifesto',
    subtitle:
      'A Veroo é verdade, é café com identidade… Buscamos fortalecer a produção e o consumo de café especial no Brasil por meio de um comércio justo e transparente. Olhamos para todos os atores da cadeia produtiva (produtores, consumidores, setor logístico e intermediários).',
    smartImage: { url: '/images/landing/about-us/manifest.jpg' },
    smartImageProps: {
      resolutionSizes: {
        url: '/images/landing/about-us/manifest@2x.jpg 2x, /images/landing/about-us/manifest.jpg 1x',
      },
      imageProps: { width: 231, height: 231 },
    },
  },
  {
    title: 'Produtores',
    subtitle:
      'A Veroo dá mais visibilidade para os produtores e demais trabalhadores envolvidos na cadeia de valor do café. Priorizamos a sustentabilidade e a produção consciente, e obviamente de contar as belas histórias desses artistas dos cafés aos Veroo Lovers. Conheça mais sobre os produtores Veroo.',
    smartImage: { url: '/images/landing/about-us/producers.jpg' },
    smartImageProps: {
      resolutionSizes: {
        url: '/images/landing/about-us/producers@2x.jpg 2x, /images/landing/about-us/producers.jpg 1x',
      },
      imageProps: { width: 231, height: 231 },
    },
  },
  {
    title: 'Sustentabilidade',
    subtitle:
      'O modelo de sustentabilidade aqui na Veroo não envolve apenas nosso cuidado com meio ambiente, mas também está relacionado com o incentivo para todos os atores da cadeia de valor. Lutamos por um comércio mais justo e consumo o consciente. Aqui na Veroo nossos produtos possuem identidade e rastreabilidade.',
    smartImage: { url: '/images/landing/about-us/sustainability.jpg' },
    smartImageProps: {
      resolutionSizes: {
        url: '/images/landing/about-us/sustainability@2x.jpg 2x, /images/landing/about-us/sustainability.jpg 1x',
      },
      imageProps: { width: 231, height: 231 },
    },
  },
];
