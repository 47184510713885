import { SubscriptionProductGridFieldsFragment } from 'core/lib/graphql/fragments/_gen/subscription-product-grid-fragment';
import { ProductFieldsFragment } from 'core/lib/graphql/store/_gen/product';
import { FinalityKind, InfoKind, Maybe } from 'core/lib/graphql/types.d';

export interface DeliveryFreight {
  postalCode?: Maybe<string>;
  deliveryInfo?: {
    days?: Maybe<number>;
    type?: InfoKind;
    hideObservation?: boolean;
    observation?: Maybe<string>;
  };
  price?: Maybe<number>;
}

export type CartProduct = {
  product: ProductFieldsFragment;
  minimumQuantity?: number;
  productGrid?: SubscriptionProductGridFieldsFragment;
};

export type Promocode = {
  code: string | null;
  usage: FinalityKind;
};

export type CartDialog = 'promocode' | 'postalCode' | 'installments';
export type EcommerceCartDialog = CartDialog | 'removeItem' | 'cartSoldOut';
export type OrderReviewDialog = CartDialog | EcommerceCartDialog | 'emptyCart';
export type SubscriptionReviewDialog = CartDialog | 'createPaymentMethod';

export enum CardStatus {
  Discount = 'Oferta',
  New = 'Novidade',
  SoldOut = 'Esgotado',
}
