import { formatter } from '@blb-ventures/library';
import { decodeId } from '@web/common/relay';
import {
  ProductListFieldsFragment,
  ProductListImagesFieldsFragment,
} from 'core/lib/graphql/store/_gen/products';
import { Item } from '@web/store/components/store-products-grid';
import { ProductKind } from 'core/lib/graphql/types.d';
import { CardStatus } from './interfaces';
import {
  productKindToQueryDict,
  SelectableProductKinds,
  coffeeKindToQueryDict,
} from './store-product-options';

export type ProductItem = ProductListFieldsFragment & ProductListImagesFieldsFragment;

export const getProductUrl = (
  item: ProductListFieldsFragment & ProductListImagesFieldsFragment,
) => {
  const categoryLabel = productKindToQueryDict[item.kind as SelectableProductKinds];
  const coffeeKindLabel = item.coffeeKind != null ? coffeeKindToQueryDict[item.coffeeKind] : '';
  const category =
    item.kind === ProductKind.Coffee ? `${categoryLabel}-${coffeeKindLabel}` : categoryLabel;
  return `/loja/${category}/${item.slug}/${decodeId(item.id).id}`;
};

const getProductStatus = (
  item: ProductListFieldsFragment & ProductListImagesFieldsFragment,
): CardStatus | undefined => {
  if (item.stock === 0) {
    return CardStatus.SoldOut;
  }
  if (item.isOnSale) {
    return CardStatus.Discount;
  }
  if (item.isFresh) {
    return CardStatus.New;
  }
  return undefined;
};

export const formatProductToItem = (
  item: ProductListFieldsFragment & ProductListImagesFieldsFragment,
  isSubscriber?: boolean,
): Item => {
  const bodyTextPrice = isSubscriber && item.clubPrice != null ? item.clubPrice : item.price;

  return {
    id: item.id,
    image: (item?.images?.length ?? 0) > 0 ? item.images[0].file : { url: '' },
    title: item.name,
    subtitle: item.region != null ? `${item.region?.name} - ${item.region?.stateCode}` : '',
    status: getProductStatus(item),
    bodyDetail: item.originalPrice && formatter.currency(item.originalPrice, 2, 2),
    bodyText: bodyTextPrice && formatter.currency(bodyTextPrice, 2, 2),
    topInfoText:
      item.onSaleDiscount != null ? `- ${(item.onSaleDiscount * 100).toFixed(0)}%` : undefined,
    href: getProductUrl(item),
  };
};
