import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PopupFieldsFragment = {
  id: string;
  title: string;
  body?: string | null;
  ctaLabel?: string | null;
  ctaAction?: string | null;
  section?: string | null;
  image?: { url: string; alt?: string | null } | null;
};

export type CurrentPopUpQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CurrentPopUpQuery = {
  currentPopUp?: {
    id: string;
    title: string;
    body?: string | null;
    ctaLabel?: string | null;
    ctaAction?: string | null;
    section?: string | null;
    image?: { url: string; alt?: string | null } | null;
  } | null;
};

export const PopupFieldsFragmentDoc = gql`
  fragment popupFields on PopUpType {
    id
    title
    body
    ctaLabel
    ctaAction
    section
    image {
      url(size: "400x400", crop: "center center")
      alt
    }
  }
`;
export const CurrentPopUpDocument = gql`
  query CurrentPopUp {
    currentPopUp {
      ...popupFields
    }
  }
  ${PopupFieldsFragmentDoc}
`;

/**
 * __useCurrentPopUpQuery__
 *
 * To run a query within a React component, call `useCurrentPopUpQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentPopUpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentPopUpQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentPopUpQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentPopUpQuery, CurrentPopUpQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentPopUpQuery, CurrentPopUpQueryVariables>(
    CurrentPopUpDocument,
    options,
  );
}
export function useCurrentPopUpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentPopUpQuery, CurrentPopUpQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentPopUpQuery, CurrentPopUpQueryVariables>(
    CurrentPopUpDocument,
    options,
  );
}
export type CurrentPopUpQueryHookResult = ReturnType<typeof useCurrentPopUpQuery>;
export type CurrentPopUpLazyQueryHookResult = ReturnType<typeof useCurrentPopUpLazyQuery>;
export type CurrentPopUpQueryResult = Apollo.QueryResult<
  CurrentPopUpQuery,
  CurrentPopUpQueryVariables
>;
