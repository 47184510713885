import NextLink from 'next/link';
import { FC } from 'react';
import { BreakPoint, GridFlatList } from '@web/common/components/grid-flat-list';
import { StoreMoreCard } from './store-more-card';
import { StoreProductCard, StoreProductCardProps } from './store-product-card';

export interface Item extends StoreProductCardProps {
  id: string;
  href?: string;
}

interface StoreProductsGridProps {
  /** When the screen is below this breakpoint it stops being a grid and starts being
   *  a horizontally scroll container  */
  horizontalBreakpoint?: BreakPoint;
  /** The minimum width of the cards when in horizontal mode */
  horizontalItemMinWidth?: string;
  /** A list of products */
  items: Item[];
  /** Whether the product images should be lazy loaded or not */
  lazy?: boolean;
  /** Configuration for the fetch more button, if null it will not show the fetch more button */
  moreButton?: {
    /** The target url for the fetch more button */
    url?: string;
    /** Secondary text below "Ver mais" */
    subtitle?: string;
    /** Handler function when the card is clicked */
    onClick?: () => void;
    /** The vertical padding number (from theme.spacing) of the card, default number: 6 */
    paddingY?: number;
  };
}

export const StoreProductsGrid: FC<StoreProductsGridProps> = ({
  horizontalBreakpoint,
  horizontalItemMinWidth = '168px',
  items,
  moreButton,
  lazy,
}) => {
  const StoreMoreButton = (
    <StoreMoreCard
      title="Ver mais"
      subtitle={moreButton?.subtitle}
      onClick={moreButton?.url != null ? undefined : moreButton?.onClick}
      horizontalBreakpoint={horizontalBreakpoint}
      horizontalItemMinWidth={horizontalItemMinWidth}
      paddingY={moreButton?.paddingY}
    />
  );

  return (
    <GridFlatList horizontalBreakpoint={horizontalBreakpoint}>
      {items.map(item =>
        item.href ? (
          <NextLink href={item.href} passHref key={item.id}>
            <StoreProductCard
              {...item}
              lazy={lazy}
              horizontalBreakpoint={horizontalBreakpoint}
              horizontalItemMinWidth={horizontalItemMinWidth}
            />
          </NextLink>
        ) : (
          <StoreProductCard
            key={item.id}
            {...item}
            lazy={lazy}
            horizontalBreakpoint={horizontalBreakpoint}
            horizontalItemMinWidth={horizontalItemMinWidth}
          />
        ),
      )}
      {moreButton?.url != null ? (
        <NextLink href={moreButton.url} passHref>
          {StoreMoreButton}
        </NextLink>
      ) : moreButton != null ? (
        StoreMoreButton
      ) : null}
    </GridFlatList>
  );
};
