import * as Types from '../../types';

import { gql } from '@apollo/client';
export type ProductDetailsFragment = {
  quantity: number;
  quantityStr?: string | null;
  coffeeKind?: Types.ProductCoffeeKind | null;
  kind: Types.ProductKind;
  altitude?: number | null;
  characteristics?: string | null;
  score?: number | null;
  stock: number;
  olfactory?: string | null;
  visual?: string | null;
  taste?: string | null;
  unit: Types.ProductUnit;
  metaTitle: string;
  metaDescription: string;
  descriptionHtml: string;
  scaReportUrl?: string | null;
  width?: number | null;
  height?: number | null;
  depth?: number | null;
  weight?: number | null;
  foodPairing?: string | null;
  originalPrice?: number | null;
  onSaleDiscount?: number | null;
  isSoldOut?: boolean | null;
  region?: {
    id: string;
    name: string;
    slug: string;
    state: string;
    stateCode: string;
    image?: { url: string; alt?: string | null } | null;
  } | null;
  feedbacks: Array<{
    id: string;
    name: string;
    comment: string;
    subtitle?: string | null;
    avatar?: { url: string; alt?: string | null } | null;
  }>;
  producer?: {
    id: string;
    name: string;
    farm: string;
    slug: string;
    descriptionHtml: string;
    video?: string | null;
    region: { name: string; state: string; stateCode: string };
    images: Array<{
      id: string;
      file?: { alt?: string | null; url: string } | null;
      original?: { alt?: string | null; url: string } | null;
      thumbnail?: { alt?: string | null; url: string } | null;
    }>;
    videoThumbnail?: { alt?: string | null; url: string } | null;
    avatar?: { alt?: string | null; url: string } | null;
  } | null;
  variety?: {
    id: string;
    name: string;
    slug: string;
    image?: { url: string; alt?: string | null } | null;
  } | null;
  images: Array<{
    id: string;
    file?: { url: string; alt?: string | null } | null;
    original?: { url: string; alt?: string | null } | null;
    thumbnail?: { url: string; alt?: string | null } | null;
  }>;
  grids: Array<{
    id: string;
    stock: number;
    value: { id: string; name: string; option: { id: string; name: string } };
  }>;
};

export const ProductDetailsFragmentDoc = gql`
  fragment productDetails on ProductType {
    quantity
    quantityStr
    coffeeKind
    kind
    altitude
    characteristics
    score
    stock
    olfactory
    visual
    taste
    unit
    metaTitle
    metaDescription
    descriptionHtml
    scaReportUrl
    width
    height
    depth
    weight
    foodPairing
    region {
      id
      name
      slug
      state
      stateCode
      image {
        url
        alt
      }
    }
    feedbacks {
      id
      name
      comment
      subtitle
      avatar {
        url
        alt
      }
    }
    originalPrice
    onSaleDiscount
    producer {
      id
      name
      farm
      slug
      descriptionHtml
      region {
        name
        state
        stateCode
      }
      images {
        id
        file {
          alt
          url(size: "600x600", crop: "center center")
        }
        original: file {
          alt
          url
        }
        thumbnail: file {
          alt
          url(size: "95x95", crop: "center center")
        }
      }
      video
      videoThumbnail {
        alt
        url(size: "95x95", crop: "center center")
      }
      avatar {
        alt
        url(size: "95x95", crop: "center center")
      }
    }
    variety {
      id
      name
      slug
      image {
        url
        alt
      }
    }
    images {
      id
      file {
        url(size: "500x500", crop: "center center")
        alt
      }
      original: file {
        url
        alt
      }
      thumbnail: file {
        url(size: "95x95", crop: "center center")
        alt
      }
    }
    isSoldOut
    grids {
      id
      value {
        id
        name
        option {
          id
          name
        }
      }
      stock
    }
  }
`;
