import { List, ListItem, ListItemAvatar, ListItemText, Paper } from '@mui/material';
import { CenteredRoundedImage } from '@web/common/styles/admin';
import { FC } from 'react';
import styled from '@emotion/styled';
import { SmartImageType } from '../../../common/smart-image';

interface TestimonialProps {
  image?: SmartImageType | null;
  name: string;
  lifetime: string;
  description: string;
  lazy?: boolean;
}

const StyledPaper = styled(Paper)`
  display: flex;
  align-self: stretch;
  min-height: ${props => props.theme.spacing(25)};
  margin: ${props => props.theme.spacing(1)};
`;

const TestimonialImage = styled(CenteredRoundedImage)`
  width: 40px;
  height: 40px;
`;

export const TestimonialBox: FC<TestimonialProps> = ({
  name,
  image,
  lifetime,
  description,
  lazy,
}) => {
  return (
    <StyledPaper>
      <List>
        <ListItem>
          <ListItemAvatar>
            {image != null ? <TestimonialImage image={image} lazy={lazy} /> : <div />}
          </ListItemAvatar>
          <ListItemText primary={name} secondary={lifetime} />
        </ListItem>
        <ListItem>
          <ListItemText
            secondary={description}
            secondaryTypographyProps={{
              align: 'left',
            }}
          />
        </ListItem>
      </List>
    </StyledPaper>
  );
};
