import { FC, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled';

const TextEllipsisBox = styled(Typography)`
  max-width: 100%;
  width: calc(40vw - 1rem);
  white-space: nowrap;
  overflow: hidden; /* "overflow" value must be different from "visible" */
  text-overflow: ellipsis;
  ${props => props.theme.breakpoints.down('sm')} {
    width: calc(80vw - 1rem);
  }
`;

interface FaqProps {
  id: string;
  question: string;
  answer: string;
  iconColor?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error';
  className?: string;
}

export const Faq: FC<FaqProps> = ({ id, className, question, answer, iconColor = 'secondary' }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion onChange={() => setExpanded(state => !state)} className={className}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon color={iconColor} />}
        aria-controls={`panel${id}-content`}
        id={`panel${id}-header`}
      >
        <Container disableGutters>
          <Typography variant="subtitle1" component="h3">
            {question}
          </Typography>
          <TextEllipsisBox
            className={expanded ? 'isExpanded' : ''}
            variant="body2"
            color="textSecondary"
          >
            {answer}
          </TextEllipsisBox>
        </Container>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2" component="h4" color="textSecondary">
          {answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
