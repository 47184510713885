import { SessionStorageObjectItem } from '../storage/session-storage-object-item';

export const MELIUZ_STORAGE_KEY = 'meliuz';

export class MeliuzStorage extends SessionStorageObjectItem<Record<'cashbackCode', string>> {
  static instance: MeliuzStorage;

  static getInstance() {
    if (MeliuzStorage.instance == null) {
      MeliuzStorage.instance = new MeliuzStorage(MELIUZ_STORAGE_KEY);
    }
    return MeliuzStorage.instance;
  }
}

export const meliuzStorage = MeliuzStorage.getInstance();
