import styled from '@emotion/styled';
import { Box, Button, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Faq } from '@web/club-veroo/faq';
import { PlansSelector } from '@web/club-veroo/plans-selector';
import { addApolloState, initializeApollo } from '@web/common/apollo';
import { CarouselBannersWithFallback } from '@web/common/components/banner/carousel-banners-with-fallback';
import { GenericSectionContainer } from '@web/common/components/generic-section-container';
import { getIndexLayout } from '@web/common/components/get-index-layout';
import { Page } from '@web/common/components/get-page-type';
import { Loader } from '@web/common/components/loaders/loader';
import { FAQData } from '@web/common/components/microdata/faq-data';
import { SEO } from '@web/common/components/seo/seo';
import { verooFaq } from '@web/common/constants/faq';
import { DEFAULT_REVALIDATE } from '@web/common/constants/isr';
import { CAROUSEL_CHANGE_TIMEOUT } from '@web/common/constants/timers';
import { Feature } from '@web/common/feature-interface';
import { useDialogs } from '@web/common/hooks/use-open-dialog';
import { useSaveMeliuzCashbackCode } from '@web/common/hooks/use-save-meliuz-cashback-code';
import { useSavePromocode } from '@web/common/hooks/use-save-promocode';
import { useSupportedPlansWithDiscount } from '@web/common/hooks/use-supported-plans-with-discount';
import { useTriggerOnPosition } from '@web/common/hooks/use-trigger-on-position';
import { useUtmLeadEventCreate } from '@web/common/hooks/use-utm-lead-event-create';
import { StyledContainerWithSections } from '@web/common/layout/styled-container-with-sections';
import { decodeId } from '@web/common/relay';
import { captureException } from '@web/common/sentry';
import { AboutUsCarousel } from '@web/home/components/about-us/about-us-carousel';
import { FeatureCarousel } from '@web/home/components/feature-carousel';
import { IndexHowItWorks } from '@web/home/components/index-how-it-works';
import { MediaSection } from '@web/home/components/media-section';
import { TestimonialsCarousel } from '@web/home/components/testimonials/testimonials-carousel';
import { StoreProductsGrid } from '@web/store/components/store-products-grid';
import { formatProductToItem } from '@web/store/map-product-to-items';
import {
  ProductListFieldsFragment,
  ProductListImagesFieldsFragment,
  ProductsDocument,
  ProductsQuery,
  ProductsQueryVariables,
  useProductsQuery,
} from 'core/lib/graphql/store/_gen/products';
import {
  BannerKind,
  FinalityKind,
  ProductCoffeeKind,
  SubscriptionKind,
  SubscriptionStatus,
} from 'core/lib/graphql/types.d';
import {
  BannersDocument,
  BannersQuery,
  BannersQueryVariables,
  useBannersQuery,
} from 'core/lib/graphql/_gen/banners';
import {
  BoxesDocument,
  BoxesQuery,
  BoxesQueryVariables,
  useBoxesQuery,
} from 'core/lib/graphql/_gen/boxes';
import {
  CurrentPopUpDocument,
  CurrentPopUpQuery,
  CurrentPopUpQueryVariables,
  useCurrentPopUpQuery,
} from 'core/lib/graphql/_gen/current-popup';
import { useMeQuery } from 'core/lib/graphql/_gen/me';
import {
  SubscriptionPlansDocument,
  SubscriptionPlansQuery,
  SubscriptionPlansQueryVariables,
  useSubscriptionPlansQuery,
} from 'core/lib/graphql/_gen/plans';
import {
  TestimonialsDocument,
  TestimonialsQuery,
  TestimonialsQueryVariables,
  useTestimonialsQuery,
} from 'core/lib/graphql/_gen/testimonials';
import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { MouseEvent, useCallback, useMemo, useState } from 'react';

const SLIDES_MARGIN = 16;

enum Sections {
  BANNERS = 'banners',
  LAST_EDITIONS = 'lastEditions',
  HOW_IT_WORKS = 'howItWorks',
  OUR_PLANS = 'ourPlans',
  TESTIMONIALS = 'Testimonials',
  OUR_STORE = 'outStore',
  ABOUT_US = 'aboutUs',
  IN_MIDIA = 'inMidia',
  COMMON_QUESTIONS = 'commonQuestions',
}

const sections = Object.values(Sections);

const CookieBanner = dynamic(
  () =>
    import('@web/common/components/cookie-banner/cookie-banner').then(
      (mod: any) => mod.CookieBanner,
    ),
  { ssr: false },
);

const SubscriptionExistsDialog = dynamic<any>(
  () => import('@web/store/components/dialogs/subscription-exists-dialog'),
);

const AccordionContent = styled(Container)`
  margin-bottom: ${props => props.theme.spacing(8)};
`;

const CTAWrapper = styled.div`
  width: 350px;
  max-width: 100%;
  margin-top: ${props => props.theme.spacing(3)};
  margin-left: auto;
  margin-right: auto;
`;

type Dialogs = 'promoPopUp' | 'subscriptionExists';

const PromoPopUpDialog = dynamic(() => import('@web/home/components/dialog/promo-popup-dialog'), {
  ssr: true,
  loading: () => <Loader />,
});

const productsOrderBy = ['-isFeatured', '-createdAt'];

const subscribeUrl = '/#assine';

const Home: Page = () => {
  const router = useRouter();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  // States
  const dialogs = useDialogs<Dialogs>();
  const [selectedCoffeeKind, setSelectedCoffeeKind] = useState<ProductCoffeeKind>(
    ProductCoffeeKind.Beans,
  );
  const [selectedPlanKind, setSelectedPlanKind] = useState<SubscriptionKind>(
    SubscriptionKind.Yearly,
  );

  // GraphQL
  const meQ = useMeQuery();
  const productsQ = useProductsQuery({
    variables: { finality: FinalityKind.Ecommerce, first: 4, orderby: productsOrderBy },
  });
  const testimonialsQ = useTestimonialsQuery();
  const subscriptionPlansQ = useSubscriptionPlansQuery();
  const bannersQ = useBannersQuery({ variables: { kind: BannerKind.LandingPage } });
  const currentPopupQ = useCurrentPopUpQuery();
  const boxesQ = useBoxesQuery();

  const formatProductToItemFn = useCallback(
    (node: ProductListFieldsFragment & ProductListImagesFieldsFragment) =>
      formatProductToItem(node, meQ.data?.me?.isSubscriber),
    [meQ.data?.me],
  );

  // GraphQL Memos
  const banners = useMemo(() => bannersQ.data?.banners ?? [], [bannersQ.data]);
  const testimonials = useMemo(() => testimonialsQ.data?.testimonials ?? [], [testimonialsQ.data]);
  const products = useMemo(
    () => (productsQ.data?.products?.edges ?? []).map(it => formatProductToItemFn(it!.node!)),
    [productsQ.data, formatProductToItemFn],
  );
  const currentPopUp = useMemo(() => currentPopupQ.data?.currentPopUp, [currentPopupQ.data]);
  const canOpenPopUp = useMemo(
    () =>
      Object.keys(router.query).length === 0 &&
      currentPopUp?.section != null &&
      currentPopUp?.image != null &&
      sections.includes(currentPopUp.section as Sections),
    [currentPopUp?.image, currentPopUp?.section, router.query],
  );
  const plans = useMemo(() => subscriptionPlansQ.data?.plans ?? [], [subscriptionPlansQ.data]);
  const customizedPlan = useMemo(
    () => plans.find(plan => plan.coffeeKind == null && plan.kind === selectedPlanKind),
    [plans, selectedPlanKind],
  );
  const { supportedPlansWithDiscount } = useSupportedPlansWithDiscount(plans, selectedCoffeeKind);
  const currentPlans = useMemo(
    () =>
      plans.filter(
        plan => plan.coffeeKind === selectedCoffeeKind && plan.kind === selectedPlanKind,
      ),
    [plans, selectedCoffeeKind, selectedPlanKind],
  );
  const boxes: Feature[] = useMemo(
    () =>
      (boxesQ.data?.boxes?.edges ?? []).map(
        it =>
          ({
            id: it?.node?.id,
            title: it?.node?.title,
            subtitle: it?.node?.subtitle,
            smartImage: (it?.node?.images?.length ?? 0) > 0 ? it?.node?.images[0].file : '',
            smartImageProps: { lazy: true, lazyProps: { height: 156 } },
          } as Feature),
      ),
    [boxesQ.data],
  );

  const customizedPlanId = customizedPlan?.id != null ? decodeId(customizedPlan?.id).id : '';
  const hasSubscription =
    meQ.data?.me?.currentSubscription != null &&
    meQ.data?.me?.currentSubscription?.status !== SubscriptionStatus.Canceled;

  const handleLinkableSubscription = useCallback(
    (e: MouseEvent) => {
      if (hasSubscription) {
        e.preventDefault();
        dialogs.openDialog('subscriptionExists');
      }
    },
    [dialogs, hasSubscription],
  );

  const TestimonialsCarouselEl = useMemo(() => {
    const slidesPerView = mdUp ? 2 : 1;
    return (
      <TestimonialsCarousel
        testimonials={testimonials}
        hasPagination
        hasNavigation={slidesPerView < 2}
        delay={CAROUSEL_CHANGE_TIMEOUT}
        slidesPerView={slidesPerView}
      />
    );
  }, [mdUp, testimonials]);

  // Hooks
  useSaveMeliuzCashbackCode();
  useSavePromocode();
  useTriggerOnPosition({
    elementId: currentPopUp?.section ?? '',
    callbackCondition: canOpenPopUp,
    callback: () => dialogs.openDialog('promoPopUp'),
  });
  useUtmLeadEventCreate({});

  return (
    <>
      <Loader loading={false} />
      <SEO title="Cafés por assinatura, no conforto da sua casa." />
      <CookieBanner />
      <StyledContainerWithSections fixed={!mdUp}>
        <CarouselBannersWithFallback id={Sections.BANNERS} banners={banners} />
        <GenericSectionContainer
          id={Sections.HOW_IT_WORKS}
          title="Como funciona a Assinatura de Cafés?"
          titleProps={{ align: 'center' }}
          disableGutters
        >
          <IndexHowItWorks />
        </GenericSectionContainer>
        <GenericSectionContainer
          id={Sections.OUR_PLANS}
          title="Nossos Planos"
          titleProps={{ align: 'center' }}
          subtitle="Estes são os planos disponíveis para assinar a Veroo."
          subtitleProps={{ align: 'center' }}
          disableGutters
        >
          <PlansSelector
            customizedPlanId={customizedPlanId}
            selectedKind={selectedCoffeeKind}
            onPlanClick={handleLinkableSubscription}
            plans={currentPlans}
            plansOnChange={(e, kind) => {
              e.preventDefault();
              setSelectedCoffeeKind(kind);
              !plans.some(plan => plan.coffeeKind === kind && plan.kind === selectedPlanKind) &&
                setSelectedPlanKind(SubscriptionKind.Monthly);
            }}
            supportedPlans={supportedPlansWithDiscount}
            currentPlanKind={selectedPlanKind}
            onPlanKindChange={(e, kind) => {
              e.preventDefault();
              setSelectedPlanKind(kind);
            }}
            id="assine"
          />
        </GenericSectionContainer>
        <GenericSectionContainer
          id={Sections.LAST_EDITIONS}
          title="Últimas edições"
          titleProps={{ align: 'center' }}
          subtitle="Confira as últimas Veroo box que os assinantes receberam"
          subtitleProps={{ align: 'center' }}
          disableGutters
        >
          {boxes.length > 0 && (mdUp || mdDown) ? (
            <FeatureCarousel
              features={boxes.slice(0, 4)}
              hasNavigation={mdDown}
              centeredSlides={mdDown}
              slidesPerView={mdDown ? 1.2 : 4}
              swiperProps={{ spaceBetween: mdDown ? 1 : SLIDES_MARGIN }}
              loop={false}
            />
          ) : null}
          <CTAWrapper>
            <Button href={subscribeUrl} size="large" variant="outlined" color="primary" fullWidth>
              Assinar
            </Button>
          </CTAWrapper>
        </GenericSectionContainer>
        <GenericSectionContainer
          id={Sections.TESTIMONIALS}
          title="Opinião de quem assina a Veroo"
          titleProps={{ align: 'center' }}
          disableGutters
        >
          <Box mt={3}>
            {TestimonialsCarouselEl}
            <CTAWrapper>
              <Button href={subscribeUrl} size="large" variant="outlined" color="primary" fullWidth>
                Quero assinar
              </Button>
            </CTAWrapper>
          </Box>
        </GenericSectionContainer>
        {products.length > 0 && (
          <GenericSectionContainer
            id={Sections.OUR_STORE}
            title="Nossa loja"
            titleProps={{ align: 'center' }}
            subtitle="Ahh… pra você que não deseja assinar e ama o universo dos cafés especiais, temos nossa loja recheada de ofertas e produtos pra lá de especiais. Conheça!"
            subtitleProps={{ align: 'center' }}
            disableGutters
          >
            <StoreProductsGrid
              items={products}
              horizontalBreakpoint="sm"
              moreButton={
                (productsQ.data?.products?.totalCount ?? 0) > 4 ? { url: '/loja' } : undefined
              }
              lazy
            />
            <Typography variant="body2" color="textSecondary" align="center">
              Arraste para o lado para ver mais
            </Typography>
          </GenericSectionContainer>
        )}
        <GenericSectionContainer
          id={Sections.ABOUT_US}
          title="Sobre nós"
          titleProps={{ align: 'center' }}
          disableGutters
        >
          <AboutUsCarousel />
        </GenericSectionContainer>
        <GenericSectionContainer
          id={Sections.IN_MIDIA}
          title="Na mídia"
          titleProps={{ align: 'center' }}
          subtitle="Aqui está um pouquinho onde falaram de nós"
          subtitleProps={{ align: 'center' }}
          disableGutters
        >
          <MediaSection />
        </GenericSectionContainer>
        <GenericSectionContainer
          id={Sections.COMMON_QUESTIONS}
          title="Dúvidas Frequentes"
          titleProps={{ align: 'center' }}
          disableGutters
        >
          <AccordionContent disableGutters>
            {verooFaq.map(it => (
              <div key={it.id}>
                <Faq id={it.id} answer={it.answer} question={it.question} iconColor="action" />
              </div>
            ))}
          </AccordionContent>
        </GenericSectionContainer>
      </StyledContainerWithSections>
      {dialogs.load.promoPopUp && (
        <PromoPopUpDialog
          maxWidth="xs"
          open={dialogs.open === 'promoPopUp'}
          onClose={dialogs.handleCloseDialog}
          item={currentPopUp!}
        />
      )}
      {dialogs.load.subscriptionExists && (
        <SubscriptionExistsDialog
          open={dialogs.open === 'subscriptionExists'}
          onClose={dialogs.handleCloseDialog}
          btnLabel="Ver minha assinatura"
          url="/admin/subscription"
        />
      )}
      <FAQData />
    </>
  );
};

Home.getLayout = getIndexLayout;

export const getStaticProps: GetStaticProps = async () => {
  const apolloClient = initializeApollo(undefined);
  try {
    await Promise.all([
      apolloClient.query<TestimonialsQuery, TestimonialsQueryVariables>({
        query: TestimonialsDocument,
      }),
      apolloClient.query<BannersQuery, BannersQueryVariables>({
        query: BannersDocument,
        variables: { kind: BannerKind.LandingPage },
      }),
      apolloClient.query<ProductsQuery, ProductsQueryVariables>({
        query: ProductsDocument,
        variables: { finality: FinalityKind.Ecommerce, first: 4, orderby: productsOrderBy },
      }),
      apolloClient.query<CurrentPopUpQuery, CurrentPopUpQueryVariables>({
        query: CurrentPopUpDocument,
      }),
      apolloClient.query<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>({
        query: SubscriptionPlansDocument,
      }),
      apolloClient.query<BoxesQuery, BoxesQueryVariables>({ query: BoxesDocument }),
    ]);
  } catch (e) {
    captureException(e);
  }
  return { ...addApolloState(apolloClient, { props: {} }), revalidate: DEFAULT_REVALIDATE };
};

export default Home;
