import styled from '@emotion/styled';
import { SmartImage } from '@web/common/smart-image';
import { FC } from 'react';
import { LayoutBannerProps } from './interface';

export const BannerWrapper = styled.div<{ hasBorderRadius?: boolean }>`
  img {
    max-width: 100%;
    height: auto;
  }
`;

const DesktopBanner = styled(SmartImage)`
  ${props => props.theme.breakpoints.down('md')} {
    display: none;
  }
`;

const MobileBanner = styled(SmartImage)`
  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const Banner: FC<LayoutBannerProps> = ({
  bannerDesktop,
  bannerMobile,
  hasBorderRadius = false,
}) => (
  <BannerWrapper data-e2e="layout-banner-content" hasBorderRadius={hasBorderRadius}>
    <DesktopBanner
      image={bannerDesktop ?? { url: '/images/landing/home-banner-desktop.jpg' }}
      imageProps={{ width: 1232, height: 528 }}
    />
    <MobileBanner
      image={bannerMobile ?? { url: '/images/landing/home-banner-mobile.jpg' }}
      imageProps={{ width: 552, height: 552 }}
    />
  </BannerWrapper>
);

export default Banner;
